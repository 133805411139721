import { Heading, Paragraph } from '@hexa-ui/components';
import { Container } from './PageTitle.styles';

interface PageTitleProps {
    title: string
    hint?: string
    size?: "H3" | "H1" | "Hero" | "H2" | "H4" | "H5"
    marginBottom?: string
}

const PageTitle = ({ title, hint, marginBottom, size = 'H2' }: PageTitleProps): JSX.Element => {
    return (
        <Container marginBottom={marginBottom} >
            <Heading size={size}>{title}</Heading>
            {hint ? <Paragraph css={{ color: '#757575', marginTop: '1rem' }}>{hint}</Paragraph> : null}
        </Container>
    );
};

export default PageTitle;