import { Card, IconButton, Tabs } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiDrupal } from '../../../Api/Api';
import StyledAlert from '../../../components/Alert/Alert';
import StyledButton from '../../../components/Button/Button';
import ExportStatus from '../../../components/ExportStatus';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import StyledTable from '../../../components/Table/Table';
import StyledTabs from '../../../components/Tabs/Tabs';
import useTimeWindowHandler from '../../../hook/useTimeWindowHandler';
import { useAppSelector } from '../../../store';
import { TimeWindowType } from '../../../store/dataTypes/timeWindow.type';
import { base_url } from '../../../utils/constants';
import timeWindowHeaders from '../../json/columnHeaders/TimeWindowHeaders.json';
import timeWindowStaticHeaders from '../../json/columnHeaders/TimeWindowStaticHeaders.json';
import handleFilter from '../extra/filter';
import ExportModule from '../modules/ExportModal';

const TimeWindowTab: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getAllTimeWindow, deleteTimeWindow } = useTimeWindowHandler();
  const campaignDetailStatus: string = useAppSelector((state) => state.campaignDetails.data?.status);
  const [timewindowFilteredData, setTimeWindowFilteredData] = useState<TimeWindowType[] | null>(
    null
  );
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [timeWindowData, setTimeWindowData] = useState<TimeWindowType[]>();
  const [prizes, setPrizes] = useState([]);
  const timeWindowFields = ['id', 'name', 'draw_date'];
  const [selectedTimeWindows, setSelectedTimeWindows] = useState<any>([]);

  const handleRowSelect = (rowSelectEvent) => {
    const itemId = (rowSelectEvent.data as TimeWindowType).id;

    setSelectedTimeWindows((prevSelectedTimeWindows) => {
      if(prevSelectedTimeWindows.includes(itemId)) {
        return prevSelectedTimeWindows.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedTimeWindows, itemId];
      }
    })
  }

  const fetchData = async () => {
    const [timeWindowResponse, prizesResponse] = await Promise.all([
      getAllTimeWindow(id),
      apiDrupal.get(`${base_url}/hub/v2/competition/${id}/prizes`),
    ]);

    const timeWindowData = InsertActions(timeWindowResponse.data.data);
    const prizesData = prizesResponse.data.data.prizes;

    setTimeWindowFilteredData(timeWindowData);
    setTimeWindowData(timeWindowData);
    setPrizes(prizesData);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const listAll = () => {
    setLoading(true);
    getAllTimeWindow(id)
      .then((response) => {
        var data: TimeWindowType[] = InsertActions(response.data.data);
        setTimeWindowFilteredData(data);
        setTimeWindowData(data);
      })
      .finally(() => setLoading(false));
  };

  const handleEdit = (value: TimeWindowType) => {
    navigate('/bees-games/manage-assets/campaign-details/' + id + '/time-window/' + value.id, {
      state: value,
    });
  };

  const handleRemove = (value: TimeWindowType) => {
    deleteTimeWindow([value.id], id).then(() => {
      setTimeWindowFilteredData([]);
      listAll();
    });
  };

  const handleRemoveArray = (value) => {
    deleteTimeWindow(value, id).then(() => {
      setTimeWindowFilteredData([]);
      setSelectedTimeWindows([]);
      listAll();
    });
  }

  const handleSearchTimeWindow = (el) => {
    setSelectedTimeWindows([]);
    setSearch(el.currentTarget.value);
    const data = InsertActions(timeWindowData);
    setTimeWindowFilteredData(data);
    handleFilter(
      el.currentTarget.value,
      data,
      timeWindowFields,
      setTimeWindowFilteredData,
      () => { }
    );
  };

  function InsertActions(data: TimeWindowType[]) {
    if (campaignDetailStatus === 'Unpublished') {
      let aux: TimeWindowType[] = [];

      const actions = (code: TimeWindowType) => (
        <FlexContainer display="inline-flex" gap="1rem">
          <IconButton icon={Edit2} variant="inherit" onClick={() => handleEdit(code)} />
          <ModalDelete
            height='8rem'
            handleDelete={() => handleRemove(code)}
            trigger={<IconButton icon={Trash2} variant="inherit" />}
            title="Delete time window?"
            description={
              <>
                Do you really wish to delete this time window?
                <br />
                This action can’t be undone, and you will need to manually restock the prizes that
                have already been distributed.
              </>
            }
          />
        </FlexContainer>
      );

      for (let i = 0; i < data.length; i++) {
        let item: TimeWindowType = data[i];
        aux[i] = Object.assign({}, item, {
          actions: actions(item),
        });
      }

      return aux;
    }
    return data
  }

  return (
    <>
      {!loading && prizes.length <= 0 && (
        <div style={{ marginBottom: '1.2rem' }}>
          <StyledAlert
            css={{ width: '100%' }}
            message="There are no prizes associated with this campaign, so it won't be possible to generate a time window."
            type="warning"
          />
        </div>
      )}
      <ExportStatus bundle="cm_time_window_v2" campaignId={id} />
      <Card elevated={'medium'} border={'large'}>
        <StyledTabs defaultValue="timewindow">
          <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
            <Tabs.Trigger value="timewindow">Time window</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="timewindow" style={{ padding: '0px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                padding: '1rem 20px',
                alignItems: 'center',
              }}
            >
              <div style={{ maxWidth: '496px', width: '496px' }}>
                <StyledFilter
                  placeholder="Start typing to filter results"
                  id="timewindow"
                  value={search}
                  onChange={(e) => handleSearchTimeWindow(e)}
                  onClear={(e) => handleSearchTimeWindow(e)}
                />
              </div>
              {campaignDetailStatus === 'Unpublished' ? <StyledButton
                variant="primary"
                icon={Plus}
                disabled={prizes.length <= 0}
                leading
                onClick={() =>
                  navigate('/bees-games/manage-assets/campaign-details/' + id + '/time-window')
                }
              >
                Generate time window
              </StyledButton> : null}
            </div>
            {selectedTimeWindows.length > 0 ?
            <div style={{ position: 'relative', ...(selectedTimeWindows.length < 10 ? { left: '220px' } : selectedTimeWindows.length >= 100 ? { left: '240px' } : { left: '230px' }) }}>
              <ModalDelete
                height='8rem'
                handleDelete={() => handleRemoveArray(selectedTimeWindows)}
                trigger={<IconButton icon={Trash2} variant="inherit" style={{ filter: 'invert(54%) sepia(89%) saturate(5215%) hue-rotate(341deg) brightness(98%) contrast(81%)' }}/>}
                title="Delete time windows?"
                description={
                  <>
                    Do you really wish to delete these time windows?
                    <br />
                    This action can’t be undone, and you will need to manually restock the prizes that
                    have already been distributed.
                  </>
                }
          />
            </div> : null}
            <StyledTable
              loading={loading}
              columns={campaignDetailStatus === 'Published' ? timeWindowStaticHeaders : timeWindowHeaders}
              data={timewindowFilteredData || []}
              pagination={{
                pageSize: 10,
                pageSizeOptions: [5, 10, 20],
                showPageSizeSelector: true,
              }}
              className={'deleteAll'}
              {
                ...(campaignDetailStatus === 'Unpublished' ? {
                  selectable: {
                    useCheckbox: true,
                    onRowSelect: (rowSelectEvent) => {
                      handleRowSelect(rowSelectEvent);
                    },
                    onAllPageRowsSelect : (rowsSelectEvent) => {
                      setSelectedTimeWindows((rowsSelectEvent.data as any[]).map((item) => item.original.id))
                    },
                    onDeselectAll: () => {
                      setSelectedTimeWindows([]);
                    }
                  }
                } : {})
              }
            />
          </Tabs.Content>
        </StyledTabs>
      </Card>
      <ExportModule
        bundle="cm_time_window_v2"
        campaignId={id}
        disabled={!!!timeWindowData?.length}
      />
    </>
  );
};

export default TimeWindowTab;
