import { Card } from '@hexa-ui/components';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../../store';

import { useParams } from 'react-router-dom';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import { AcceptanceType } from '../../../store/dataTypes/acceptance.type';
import { FETCH_ACCEPTANCE } from '../../../store/stock/AcceptanceReducer';
import AcceptanceHeaders from '../../json/columnHeaders/AcceptanceHeaders.json';
import ExportModule from '../modules/ExportModal';
import ExportStatus from '../../../components/ExportStatus';

const AcceptanceTab: React.FC = () => {
  const data: AcceptanceType[] = useAppSelector((state) => state.acceptance.data);
  const totalPages = useAppSelector((state) => state.acceptance.total);

  const dispatch = useDispatch<AppDispatch>();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [filter, setFilter] = useState('');
  const [requestData, setRequestData] = useState(null);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestData = {
      campaign_id: id,
      page: page,
      limit: limit,
      search: search,
    };
    setRequestData(requestData);
    setLoading(true);
    dispatch(FETCH_ACCEPTANCE(requestData)).finally(() => setLoading(false));
  }, []);

  const handlePagination = (page, limit) => {
    requestData.page = page;
    requestData.limit = limit;
    setRequestData(requestData);
    setLimit(limit);
    dispatch(FETCH_ACCEPTANCE(requestData));
  };

  const handleSearch = (el) => {
    setSearch(el.currentTarget.value);

    if (el.currentTarget.value.length == 0 || el.currentTarget.value.length >= 2) {
      setPage(1);
      requestData.page = 0;
      setRequestData(requestData);
      requestData.search = el.currentTarget.value;
      dispatch(FETCH_ACCEPTANCE(requestData));
    }
  };

  return (
    <>
    <ExportStatus bundle="cm_base_player_acceptance" campaignId={id} />
    <Card elevated={'medium'} border={'large'}>
      <FlexContainer
        width="100%"
        display={'inline-flex'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        padding={'1rem'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleSearch(e)}
            onClear={(e) => handleSearch(e)}
          />
        </div>
      </FlexContainer>
      <StyledTable loading={loading} columns={AcceptanceHeaders} data={data} pagination={false} />
      <FlexContainer
        display={'inline-flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        padding={'0.5rem 1rem'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        <StyledPagination
          pageSize={limit}
          total={totalPages}
          current={page}
          onChange={handlePagination}
        />
      </FlexContainer>
    </Card>
    <ExportModule
        bundle="cm_base_player_acceptance"
        campaignId={id}
        disabled={!!!data?.length}
      />
    </>
  );
};

export default AcceptanceTab;
