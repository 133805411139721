import { Card } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import StyledAlert from '../../../components/Alert/Alert';
import StyledButton from '../../../components/Button/Button';
import { DisplayFilter } from '../../../components/CheckboxFilter/CheckboxFilter';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import { AppDispatch, useAppSelector } from '../../../store';
import { DigitalPrizeCodeType } from '../../../store/dataTypes/digitalPrizeCode.type';
import {
  FETCH_DIGITAL_PRIZE_CODE,
  IMPORT_DIGITAL_PRIZE_CODE_CHECK,
} from '../../../store/stock/DigitalPrizeCodeReducer';
import { FilterByCheckbox } from '../../../utils/filterByCheckbox';
import { changeState } from '../../../utils/functions';
import Headers from '../../json/columnHeaders/DigitalPrizeCodeHeaders.json';

const DigitalPrizeCodeTab: React.FC = () => {
  const codesData: DigitalPrizeCodeType[] = useAppSelector((state) => state.digitalPrizeCode.data);
  const totalPages = useAppSelector((state) => state.digitalPrizeCode.total);
  const [filteredData, setFilteredData] = useState<DigitalPrizeCodeType[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [total, setTotal] = useState(totalPages);
  const [checkboxListFilters, setCheckboxListFilters] = useState({});
  const [requestData, setRequestData] = useState(null);
  const { id } = useParams();

  const importCheck = useAppSelector((state) => state.digitalPrizeCode.check);
  const [digitalPrizeCodeImportCheck, setDigitalPrizeCodeImportCheck] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestData = {
      campaign_id: id,
      page: page,
      limit: limit,
      search: search,
    };
    setRequestData(requestData);
    async function init() {
      await dispatch(IMPORT_DIGITAL_PRIZE_CODE_CHECK({ id })).finally(() =>
        setDigitalPrizeCodeImportCheck(importCheck)
      );
      await dispatch(FETCH_DIGITAL_PRIZE_CODE(requestData));
    }

    init().finally(() => { });
    setLoading(true);
    dispatch(FETCH_DIGITAL_PRIZE_CODE(requestData)).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setTotal(totalPages);

    Array.isArray(codesData) ? setFilteredData(codesData) : setFilteredData([]);
  }, [codesData]);

  useEffect(() => {
    const data = FilterByCheckbox(codesData, checkboxListFilters, { status: 'status' });
    setFilteredData(data);
    setTotal(data?.length);
  }, [checkboxListFilters]);

  const handlePagination = (page, limit) => {
    if (Object.keys(checkboxListFilters).length < 1) {
      requestData.page = page;
      requestData.limit = limit;
      setLimit(limit);
      setRequestData(requestData);
      dispatch(FETCH_DIGITAL_PRIZE_CODE(requestData));
    }
  };

  const handleSearch = (value) => {
    if (value.length == 0 || value.length >= 2) {
      setPage(1);
      requestData.page = 0;
      setRequestData(requestData);
      requestData.search = value;
      dispatch(FETCH_DIGITAL_PRIZE_CODE(requestData));
    }
  };

  const debouncedSearch = useCallback(
    debounce((nextValue) => handleSearch(nextValue), 250),
    []
  );

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    changeState(setSearch, event.currentTarget.value);

    debouncedSearch(event.currentTarget.value);
  };

  const handleCheckboxFilter = (checks) => {
    setCheckboxListFilters(checks);
  };

  const deleteCheckboxFilter = (checks) => {
    setCheckboxListFilters(checks);
  };

  const FiltersContainer = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5rem',
  };

  const ContainerFilterTags = styled('div', {
    padding: '0px $space$6 $space$6',
  });

  return (
    <>
      {!importCheck && !loading && (
        <StyledAlert
          message={'You already have an import running. Wait for it to finish and try again!'}
          type={'warning'}
          css={{ marginBottom: '1rem' }}
          width="100%"
        />
      )}
      <Card elevated={'medium'} border={'large'} css={{ padding: '1rem 0px 0px 0px' }}>
        <FlexContainer
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'0 1rem 1rem 1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <div style={FiltersContainer}>
            {/*<CheckboxFilter
              selectedCheckboxes={checkboxListFilters}
              onChange={handleCheckboxFilter}
              menuTabs={[{ acessor: 'status', label: 'Status', key: 'status' }]}
              tabItems={{
                status: statusOptions,
              }}
            />*/}
            <div style={{ maxWidth: '496px', width: '496px' }}>
              <StyledFilter
                placeholder="Start typing to filter results"
                value={search}
                onChange={handleChange}
                onClear={(e) => {
                  setSearch('');
                  handleSearch('');
                }}
              />
            </div>
          </div>
          {Object.keys(checkboxListFilters).length > 0 ? (
            <ContainerFilterTags>
              <DisplayFilter
                checkFilters={checkboxListFilters}
                deleteFilter={deleteCheckboxFilter}
              />
            </ContainerFilterTags>
          ) : null}
          <StyledButton
            disabled={!importCheck}
            variant="primary"
            icon={Plus}
            leading
            onClick={() => {
              navigate(
                '/bees-games/manage-assets/campaign-details/' + id + '/import-digital-prizes-codes',
                {
                  state: {
                    idCampaign: id,
                  },
                }
              );
            }}
          >
            Import Digital prize codes
          </StyledButton>
        </FlexContainer>
        {
          <StyledTable
            loading={loading}
            columns={Headers}
            data={filteredData.length ? filteredData : []}
            pagination={
              Object.keys(checkboxListFilters).length < 1
                ? false
                : {
                  pageSize: 20,
                  pageSizeOptions: [5, 10, 20],
                  showPageSizeSelector: true,
                }
            }
          />
        }
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          padding={'0.75rem 1rem 0.5rem 1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <StyledPagination
            pageSize={limit}
            total={total}
            current={page}
            onChange={handlePagination}
          />
        </FlexContainer>
      </Card>
    </>
  );
};

export default DigitalPrizeCodeTab;
