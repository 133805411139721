import { Card, Grid, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { useEffect, useState } from 'react';
import StyledFilter from '../../../components/Filter/Filter';
import StyledTable from '../../../components/Table/Table';
import totalScoreHeaders from '../../json/columnHeaders/TotalScoreHeaders.json';
import handleFilter from '../extra/filter';
import ExportModule from '../modules/ExportModal';
import { useParams } from 'react-router-dom';
import useTotalScoreHandler from '../../../hook/useTotalScoreHandler';
import { totalScoreType } from '../../../store/dataTypes/totalScore.type';
import ExportStatus from '../../../components/ExportStatus';

const ActionsContainer = styled(Grid.Item, {
  justifyContent: 'space-between',
  padding: '$space$4',
  gap: '$space$05',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const TotalScoreTab: React.FC = () => {
  const [filteredData, setFilteredData] = useState<totalScoreType[]>();
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<totalScoreType[]>();
  const { id } = useParams();
  const { getRanking } = useTotalScoreHandler();

  useEffect(() => {
    setLoading(true);
    getRanking(id).then((res) => {
      const updatedRes = res.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
      setFilteredData(updatedRes);
      setData(updatedRes);
    }).finally(() => setLoading(false));
  }, []);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = data;
    if (e.currentTarget.value) {
      const searchKeys = [
        'player_name',
        'player_poc',
        'player_id',
        'seconds',
        'obstacles',
        'items',
        'letters',
        'score',
      ];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  return (
    <>
      <ExportStatus bundle="bee_run_score_ranking" campaignId={id} />
      <Card elevated={'medium'} border={'large'}>
        <ActionsContainer xs={12} sm={12} md={12} lg={12} xl={12}>
          <StyledFilter
            maxWidth="496px"
            width="496px"
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleSearch(e)}
            onClear={(e) => handleSearch(e)}
          />
        </ActionsContainer>
        {filteredData !== undefined ? (
          <StyledTable
            loading={loading}
            columns={totalScoreHeaders}
            data={filteredData || []}
            pagination={{
              pageSize: 10,
              pageSizeOptions: [5, 10, 20],
              showPageSizeSelector: true,
            }}
          />
        ) : (
          <Paragraph size='basis' css={{ margin: '2.5rem auto'}}>No ranking results for current settings.</Paragraph>
        )}
      </Card>
      <ExportModule bundle={'bee_run_score_ranking'} campaignId={id} disabled={!!!data} />
    </>
  );
};

export default TotalScoreTab;
