import { FileValidated } from '@hexa-ui/components';
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { ChallengeType, EditChallengeType, PrizeType } from '../store/dataTypes';

type GameSettingsContextType = {
  customizeGamePDF: {
    field_logo_header: number;
    field_logo_footer: number;
    header: FileValidated[];
    footer: FileValidated[];
    name_config_campaign: string;
    field_title?: string;
    field_text_before_prize_name?: string;
    field_text_before_digital_prize?: string;
    field_logo_header_alt: string;
    field_logo_footer_alt: string;
  };
  customizeGameLuckyNumber: {
    skipStep: boolean;
    csvLuckyNumbers: FileValidated[];
    csvError: boolean;
    emptyFields: boolean;
  };
  createPrize: {
    prize: PrizeType;
    image: any;
  };
  prizeList: {
    addPrize: PrizeType;
    search: string;
    editMode: boolean;
  };
  levels: {
    form: {
      levels: number;
      moves: number;
    };
    check: boolean;
  };
  tournamentSelected: {
    tournament?: any;
    groups?: any[];
    matches?: any[];
  };
  isPrizeDisabled: boolean;
  currentChallenge: {
    challenge: ChallengeType;
  }
};

type GameSettingsContextProps = {
  gameSettingsState: GameSettingsContextType;
  setGameSettingsState: Dispatch<SetStateAction<GameSettingsContextType>>;
  setChallengeState: Dispatch<SetStateAction<EditChallengeType>>;
  setPrizeDisabled: (value: boolean) => void;
  reset: () => void;
};

const DEFAULT_VALUE = {
  gameSettingsState: {
    header: [],
    footer: [],
    customizeGamePDF: {
      field_logo_header: null,
      field_logo_footer: null,
      field_logo_header_alt: '',
      field_logo_footer_alt: '',
      header: [],
      footer: [],
      name_config_campaign: null,
    },
    customizeGameLuckyNumber: {
      skipStep: false,
      csvLuckyNumbers: [],
      csvError: false,
      emptyFields: false
    },
    createPrize: {
      prize: null,
      image: [],
    },
    prizeList: {
      addPrize: null,
      search: null,
      editMode: false,
    },
    levels: {
      form: {
        levels: 100,
        moves: 20,
      },
      check: false,
    },
    tournamentSelected: {
      tournament: null,
      groups: [],
      matches: [],
    },
    isPrizeDisabled: true,
    currentChallenge: {
      challenge: {
        id: '',
        name: '',
        challenge_id: ''
      }
    },
  },
  setGameSettingsState: () => { },
  setChallengeState: () => { },
  setPrizeDisabled: () => { },
  reset: () => { },
};

export const GameSettingsContext = createContext<GameSettingsContextProps>(DEFAULT_VALUE);

const GameSettingsContextProvider = ({ children }: { children: any }) => {
  const [gameSettingsState, setGameSettingsState] = useState(DEFAULT_VALUE.gameSettingsState);

  const setChallengeState = (value: EditChallengeType) => {
    setGameSettingsState((prev) => ({
      ...prev,
      currentChallenge: value
    }));
  }

  const setPrizeDisabled = (value: boolean) => {
    setGameSettingsState((prev) => ({ ...prev, isPrizeDisabled: value }));
  };

  const reset = () => {
    setGameSettingsState((prev) => ({
      ...prev,
      isPrizeDisabled: true,
      tournamentSelected: {
        tournament: null,
        groups: [],
        matches: [],
      },
    }));
  };

  return (
    <GameSettingsContext.Provider
      value={{
        gameSettingsState,
        setGameSettingsState,
        setPrizeDisabled,
        setChallengeState,
        reset,
      }}
    >
      {children}
    </GameSettingsContext.Provider>
  );
};

export default GameSettingsContextProvider;
