import { apiDrupal } from "../Api/Api";
import { base_url } from "../utils/constants";

interface AccountHandleInterface {
    addExtension: (requestData) => Promise<any>,
    getAllAccounts: (requestData) => Promise<any>
    uploadAccountsCSV: (file) => Promise<any>
    importAccounts: (data) => Promise<any>
    deleteAccount: (uuid) => Promise<any>
    editAccount: (uuid, requestData) => Promise<any>
    getAccountStatus: () => Promise<any>,
    getImportType: () => Promise<any>,
    getChallenge: (requestData, accountId) => Promise<any>
}

type CSVAcccount = {
    fileName: string;
    file: any;
}

type ImportAccountType = {
    description: string,
    field_file: any,
    delimiter: string,
    countryUuid: string
}

type NewAccountType = {
    name: string,
    poc: any,
    country: string
}

type getAccountsRequest = {
    page: number;
    limit: number;
    search: string;
    country: string;
}

export default function useAccountHandler(): AccountHandleInterface {
    const addExtension = async (requestData: NewAccountType) => {
        return await apiDrupal.post(`${base_url}/jsonapi/cm_extension_account/bees_promo`,
            {
                "data": {
                    "type": "cm_extension_account--bees_promo",
                    "attributes": {
                        "name": `${requestData?.name}`,
                        "field_poc": `${requestData?.poc}`
                    },
                    "relationships": {
                        "cm_extension_account_type": {
                            "data": {
                                "type": "cm_extension_account_type--cm_extension_account_type",
                                "id": "67596fb2-617b-4ea3-96fa-2b536d62dc83"
                            }
                        },
                        "country": {
                            "data": {
                                "type": "cm_country--cm_country",
                                "id": `${requestData?.country}`
                            }
                        }
                    }
                }
            }, {
            headers: {
                'Content-Type': 'application/vnd.api+json',
                Accept: '*/*'
            }
        })
            .then(response => response.data)
            .catch(error => error.response);
    }

    const getAccountStatus = async () => {
        return await apiDrupal.get(`${base_url}/hub/v2/competition/import/cm_extension_account/status`)
            .then(response => response.data)
            .catch(error => error);

    }

    const getImportType = async () => {
        return await apiDrupal.get(`${base_url}/jsonapi/cm_import_type/cm_import_type`)
            .then(response => response.data)
            .catch(error => error);
    }

    const getChallenge = async (accountId, requestData) => {
      return await apiDrupal.get(base_url + '/hub/v2/extension-account/' + accountId + '/challenges' +
      '?page=' + (requestData.page) + '&pageSize=' + requestData.limit)
            .then(response => response.data)
            .catch(error => error);
    }

    const getAllAccounts = async (requestData: getAccountsRequest) => {
        return await apiDrupal.get(base_url + '/jsonapi/cm_extension_account/bees_promo?' + 'include=country' +
            '&filter[country-group][condition][path]=country.country&filter[country-group][condition][value]=' + requestData.country +
            '&filter[search-group][group][conjunction]=OR&filter[name][condition][path]=name&filter[name][condition][operator]=CONTAINS&filter[name][condition][value]=' + requestData.search +
            '&filter[name][condition][memberOf]=search-group' +
            '&filter[field_poc][condition][path]=field_poc&filter[field_poc][condition][operator]=CONTAINS&filter[field_poc][condition][value]=' + requestData.search +
            '&filter[field_poc][condition][memberOf]=search-group' +
            '&filter[drupal_internal__id][condition][path]=drupal_internal__id&filter[drupal_internal__id][condition][operator]=CONTAINS&filter[drupal_internal__id][condition][value]=' + requestData.search +
            '&filter[drupal_internal__id][condition][memberOf]=search-group' +
            '&filter[country][condition][path]=country.country&filter[country][condition][operator]=CONTAINS&filter[country][condition][value]=' + requestData.search +
            '&filter[country][condition][memberOf]=search-group' +
            '&page[offset]=' + (requestData.limit) * (requestData.page) + '&page[limit]=' + requestData.limit)
            .then(response => response.data)
            .catch(error => error);

    }

    const uploadAccountsCSV = async (requestData: CSVAcccount) => {
        return await apiDrupal.post(`${base_url}/file/upload/cm_import/extension_account/field_file?_format=json`, requestData.file, {
            headers: {
                'Content-Type': 'application/octet-stream',
                Accept: 'application/vnd.api+json',
                'Content-Disposition': 'aittachment; filename="' + requestData.fileName + '"',
            }
        })
            .then(response => response.data)
            .catch(error => error);
    }

    const importAccounts = async (data: ImportAccountType) => {
        return await apiDrupal.post(`${base_url}/jsonapi/cm_import/extension_account`,
            {
                "data": {
                    "type": "cm_import--extension_account",
                    "attributes": {
                        "name": `${data?.description}`,
                        "field_file_delimiter": `${data?.delimiter}`
                    },
                    "relationships": {
                        "cm_import_type": {
                            "data": {
                                "type": "cm_import_type--cm_import_type",
                                "id": "00d59d1f-3e40-4b8a-8d8a-aa31f5eb7969"
                            }
                        },
                        "field_country": {
                            "data": {
                                "type": "cm_country--cm_country",
                                "id": `${data?.countryUuid}`
                            }
                        },
                        "field_file": {
                            "data": {
                                "type": "file--file",
                                "id": `${data?.field_file}`
                            }
                        }
                    }
                }
            },
            {
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                    Accept: '*/*'
                }
            }).then(response => response.data)
            .catch(error => error);
    }

    const editAccount = async (uuid: string, requestData: any) => {
        return await apiDrupal.patch(`${base_url}/jsonapi/cm_extension_account/bees_promo/${uuid}`,
            {
                "data": {
                    "type": "cm_extension_account--bees_promo",
                    "id": `${uuid}`,
                    "attributes": {
                        "name": `${requestData?.name}`,
                        "field_poc": `${requestData?.poc}`,
                        "drupal_internal__id": `${requestData?.drupal_internal__id}`
                    },
                    "relationships": {
                        "cm_extension_account_type": {
                            "data": {
                                "type": "cm_extension_account_type--cm_extension_account_type",
                                "id": "67596fb2-617b-4ea3-96fa-2b536d62dc83"
                            }
                        },
                        "country": {
                            "data": {
                                "type": "cm_country--cm_country",
                                "id": `${requestData?.country}`
                            }
                        }
                    }
                }
            }, {
            headers: {
                'Content-Type': 'application/vnd.api+json',
                Accept: '*/*'
            }
        })
            .then(response => response.data)
            .catch(error => error.response);

    }

    const deleteAccount = async (uuid: string) => {
        return await apiDrupal.delete(`${base_url}/jsonapi/cm_extension_account/bees_promo/${uuid}`, {
            headers: {
                Accept: '*/*'
            }
        })
            .then(response => response.data)
            .catch(error => error);
    }

    return { addExtension, getAllAccounts, uploadAccountsCSV, importAccounts, getAccountStatus, deleteAccount, editAccount, getImportType, getChallenge }
}
