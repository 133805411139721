import { Card, FileValidated, Grid } from '@hexa-ui/components';
import { Download, Info } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import { InfoButtonText } from '../../components/InfoField/InfoField';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledRadio from '../../components/Radio/StyledRadio';
import StyledTextarea from '../../components/Textarea/Textarea';
import { AlertContext } from '../../contexts/alert.context';
import useChallengeHandler from '../../hook/useChallengeHandler';
import { useAppSelector } from '../../store';
import { CampaignType } from '../../store/dataTypes';
import { changeStateForm } from '../../utils/functions';
import { base64CSVConverter } from '../../utils/toBase64';
import checkValidityCSV from '../../utils/verifyCSV';

type challengeType = {
    name: string,
    description: string;
    field_base_campaign: string;
    field_file: any[];
    delimiter: string;
}

export default function ImportChallenges() {
    const navigate = useNavigate();
    const params = useParams();
    const { addToast } = useContext(AlertContext);
    const { uploadChallengeCSV, importChallenges } = useChallengeHandler();
    const campaignDetails: CampaignType = useAppSelector((state) => state.campaignDetails.data);
    const [form, setForm] = useState<challengeType>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [csvError, setCsvError] = useState<boolean>(false);
    const csvData = [['challenge']];
    const [hasEmptyField, setHasEmptyField] = useState(false);

    const handleFile = async (file: FileValidated[]) => {
        setCsvError(false);
        setLoading(true);
        let isValid = await checkValidityCSV(file[0].file, csvData[0], form?.delimiter).then((res: boolean) => res);
        if (isValid) {
            const convertedFile = await base64CSVConverter(file[0].file);
            await uploadChallengeCSV(
                {
                    fileName: String(file[0].file.name),
                    file: convertedFile,
                },
                String(params?.campaignId)
            )
                .then((data) => {
                    if (data?.message) {
                        addToast({
                            message: "Error uploading CSV",
                            type: 'error',
                        });
                        setCsvError(true)
                    } else {
                        addToast({
                            message: "Success uploading CSV",
                            type: 'success',
                        });
                        setCsvError(false)
                        changeStateForm(setForm, form, 'field_file', [{ target_id: data.fid[0].value }]);
                    }
                })
                .finally(() => setLoading(false));
        } else {
            setCsvError(true);
            setLoading(false);
        }
    };

    const handleSend = async () => {
        if (!form?.description || form?.field_file === undefined) {
            addToast({
                message: 'Please fill in all fields in challenge to continue!',
                type: 'error',
            });
            setHasEmptyField(true);
        } else {
            setHasEmptyField(false);
            setLoading(true);
            await importChallenges(
                {
                    name: campaignDetails?.name,
                    field_base_campaign: String(params?.campaignId),
                    description: form?.description,
                    field_file: form?.field_file,
                    field_file_delimiter: form?.delimiter
                },
                Number(params?.campaignId)
            )
                .then((res) => {
                    if (res?.error) {
                        if (res?.error?.code === 0) setCsvError(true);
                        addToast({
                            message: res?.error?.message,
                            type: 'error',
                        });
                    } else {
                        addToast({
                            message: 'Challenge successfuly updated',
                            type: 'success',
                        });
                        navigate(-1);
                    }
                })
                .catch(() => {
                    addToast({
                        message: 'An error occurred while updated challenge.',
                        type: 'error',
                    });
                })
                .finally(() => setLoading(false));
        }
    };

    const BtnContainer = styled('div', {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'flex-end',
        gap: '0.5rem',
        padding: '20px',
    })

    return (
        <>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
                <PageTitle
                    marginBottom="0.5rem"
                    title="Import challenges"
                    hint="Time to create your next big project! We suggest giving it a meaningful name,
              as well as a custom thumbnail. The future you says thanks!"
                />
            </Grid.Item>
            <Grid.Item style={{ marginBottom: '1.5rem', gap: 23, alignItems: 'center' }}>
                <InfoButtonText
                    icon={() => <Download size="large" />}
                    iconPosition="leading"
                    size="large"
                    css={{ gap: 11 }}
                >
                    <a
                        href={
                            'data:application/octet-stream;base64,Y2hhbGxlbmdlCjIxNjM1MgoyNDMyNjQ3MgozNDYzMjQzMjQzMgo0NjM0NjI0Mgo='
                        }
                        download="Template_challenges.csv"
                        style={{
                            color: 'black',
                            fontWeight: '500',
                            lineHeight: '1.5rem',
                            textDecoration: 'none',
                            fontFamily: 'Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif',
                        }}
                    >
                        Download template
                    </a>
                </InfoButtonText>
                <a
                    style={{ textDecoration: 'none' }}
                    target={'_blank'}
                    href="https://anheuserbuschinbev-my.sharepoint.com/:w:/g/personal/vanessa_venancio_ab-inbev_com/EQKqAk0e62JAsNVMlabUILoBtGwCtQHpna78QBQIZvYneg?e=OKt83g"
                >
                    <InfoButtonText
                        icon={() => <Info size="xlarge" />}
                        iconPosition="leading"
                        size="large"
                        css={{ gap: 11 }}
                    >
                        See instructions
                    </InfoButtonText>
                </a>
            </Grid.Item>
            <Card elevated="medium" border="medium" css={{ width: '100%' }}>
                <Grid.Container
                    type="fluid"
                    style={{
                        justifyContent: 'center',
                        padding: '1.5rem 0 1rem 0',
                    }}
                >
                    <Grid.Item xl={8} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
                        <Grid.Container type="fluid" style={{ width: '100%', rowGap: '1rem', margin: 0 }}>
                            <StyledInput
                                defaultValue={campaignDetails?.name}
                                required
                                disabled
                                size="large"
                                label="Campaign"
                                placeholder="Campaign Example"
                                hint="The current campaign you’re importing to. This cannot be changed"
                                width="100%"
                                onChange={(e) => changeStateForm(setForm, form, 'name', e.currentTarget.value)}
                            />
                            <StyledTextarea
                                style={{ height: '300px', resize: 'none' }}
                                defaultValue={form?.description}
                                value={form?.description}
                                required
                                hasError={hasEmptyField && !form?.description}
                                errorText='Description is required.'
                                label="Description*"
                                placeholder="Import made on MM/DD/YY - HH:MM"
                                width="100%"
                                type="textarea"
                                height="242px"
                                characterCounter
                                maxLength={150}
                                rows={12}
                                onChange={(e) =>
                                    changeStateForm(setForm, form, 'description', e.currentTarget.value)
                                }
                            />
                        </Grid.Container>
                    </Grid.Item>
                    <Grid.Item xl={4} lg={4} md={5} sm={12} xs={12} style={{ flexDirection: 'column' }}>
                        <StyledRadio label='Delimiter' defaultValue=',' type='horizontal' hint='Csv delimiter character must be the same as selected in this field.'
                            style={{ marginBottom: '12px', minWidth: '100% ' }}
                            options={[
                                {
                                    id: 'comma',
                                    label: ', (Comma)',
                                    value: ','
                                },
                                {
                                    id: 'semicolon',
                                    label: '; (Semicolon)',
                                    value: ';'
                                }
                            ]}
                            onChange={(value => changeStateForm(setForm, form, 'delimiter', value))}
                        />
                        <StyledFileUploader
                            legend={
                                <>
                                    The csv must have the <b>challenge</b> column.
                                </>
                            }
                            title="Import .csv file*"
                            accept=".csv"
                            maxFileSize={1024 * 1024 * 5}
                            message="File must be .csv and must be less than 5MB"
                            onDrop={(file) => handleFile(file)}
                            maxFiles={1}
                            type="file"
                            error={
                                csvError
                                    ? {
                                        message: `The csv is not valid. Columns don't match!`,
                                    }
                                    : hasEmptyField && !form?.field_file ?
                                    {
                                        message: 'File is required.'
                                    }
                                    : undefined
                            }
                        />
                    </Grid.Item>
                </Grid.Container>
                <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
                    <BtnContainer>
                        <StyledButton variant="secondary" onClick={() => navigate(-1)}>
                            Cancel
                        </StyledButton>
                        <StyledButton variant="primary" isLoading={loading} onClick={() => handleSend()}>
                            Save
                        </StyledButton>
                    </BtnContainer>
                </Grid.Item>
            </Card>
        </>
    );
}
