import { LoadingDots, Paragraph, SearchField } from '@hexa-ui/components';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import FlexContainer from '../FlexContainer';
import { Container } from './SearchDropdown.styles';

interface props {
  hint?: string;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  size?: 'large' | 'medium';
  title?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onSearch: (searchTerm: string) => Promise<any[]>;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onClickResult?: (item: any) => void;
}

const SearchDropdown: React.FC<props> = ({
  value,
  defaultValue,
  required,
  size,
  title,
  hint,
  label,
  placeholder,
  disabled,
  onSearch,
  onChange,
  onClear,
  onClickResult,
}) => {
  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any[]>([]);

  useEffect(() => {
    setSearch(value || '');
  }, [value]);

  const debouncedSearch = useCallback(
    debounce(async (nextValue) => {
      setLoading(true);
      const res = await onSearch(nextValue);
      setResults(res);
      setLoading(false);
    }, 250),
    [onSearch]
  );

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    setSearch(val);
    debouncedSearch(val);
    onChange && onChange(event);
  };

  return (
    <Container>
      <Paragraph size="small" weight="semibold">
        {label}
      </Paragraph>
      <Paragraph size="xsmall" css={{ color: '#757575' }}>
        {hint}
      </Paragraph>
      <SearchField.Root
        defaultValue={defaultValue}
        onClear={() => {
          setSearch('');
          if (onClear) onClear();
        }}
        value={search}
        required={required}
        size={size}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        onFocus={() => setFocused(true)}
      ></SearchField.Root>
      <FlexContainer
        display={focused && search && (results.length > 0 || loading) ? 'flex' : 'none'}
        gap="0.5rem"
        flexDirection="column"
        background={'#FFF'}
        width="300px"
        height="max-content"
        maxHeight="300px"
        position="absolute"
        zIndex={99}
        boxShadow="2px 4px 8px #ddd"
        border="1px solid #ddd"
        borderRadius={8}
        overflowY="auto"
        onBlur={() => setFocused(false)}
      >
        {loading ? (
          <FlexContainer
            width="100%"
            height="50px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingDots />
          </FlexContainer>
        ) : (
          <FlexContainer padding="4px" height="max-content">
            {results.map((item, index) => (
              <Paragraph
                css={{ width: '100%', cursor: 'pointer' }}
                key={index}
                onClick={() => {
                  if (onClickResult) onClickResult(item);
                  setSearch(item.name || item);
                  setFocused(false);
                }}
              >
                {item.name || item}
              </Paragraph>
            ))}
          </FlexContainer>
        )}
      </FlexContainer>
    </Container>
  );
};

export default SearchDropdown;
