import { Card, Heading, IconButton, Modal, Paragraph } from "@hexa-ui/components";
import { Edit2, Plus, Trash2 } from "@hexa-ui/icons";
import { styled } from '@hexa-ui/theme';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StyledButton from "../../components/Button/Button";
import StyledFilter from "../../components/Filter/Filter";
import FlexContainer from "../../components/FlexContainer";
import PageTitle from "../../components/PageTitle/PageTitle";
import StyledTable, { StyledPagination } from "../../components/Table/Table";
import useAccountHandler from "../../hook/useAccountHandler";
import useChallengeHandler from "../../hook/useChallengeHandler";
import { getJsonApiData } from "../../utils/formatData";
import ExportModule from "../campaignDetails/modules/ExportModal";
import headers from '../json/columnHeaders/accountListHeader.json';

export default function AccountList() {

  const Container = styled('div', {
    display: 'flex',
    justifyContent: 'space-between'
  })

  const FlexBtn = styled('div', {
    display: 'flex',
    gap: '$1'
  })

  const { getAllAccounts, getAccountStatus, deleteAccount } = useAccountHandler();
  const { getCountries } = useChallengeHandler();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [requestData, setRequestData] = useState({
    total: 0,
    page: 0,
    limit: 25,
    search: '',
    country: ''
  })
  const [importAccountStatus, setImportAccountStatus] = useState(false);

  const fetchData = async () => {
    setLoading(true)
    await getCountries(localStorage.getItem('country'), 'drupal_internal__id')
      .then(async (res) => { requestData.country = res.data[0].attributes.country })
    await getAllAccounts(requestData)
      .then(async (res) => {
        const formatData = getJsonApiData(res, true, ['country'])
        const updateData = InsertActions(formatData.data)
        setAccounts(updateData)
        setRequestData(prevState => ({ ...prevState, total: formatData.total }))
      })
      .finally(() => setLoading(false))

    //await getImportType().then(res => console.log(res))
    /*await getAccountStatus().then((error) => {
      if (error?.message) setImportAccountStatus(true);
      else setImportAccountStatus(false);
    });*/
  };

  useEffect(() => {
    fetchData();
  }, [localStorage.getItem('country')]);

  const handlePagination = (page, limit) => {
    requestData.limit = limit
    requestData.page = page >= 1 ? page - 1 : page
    fetchData();
  };

  const handleSearch = (value) => {
    requestData.search = value
    fetchData();
  };

  const debouncedSearch = useCallback(
    debounce((value) => handleSearch(value), 250),
    []
  );

  const handleChange = (el) => {
    setSearch(el.currentTarget.value);
    debouncedSearch(el.currentTarget.value)
  }

  const getCountryId = async (acronym: string) => {
    return await getCountries(acronym, 'country')
  }

  const getCountry = async (countryCode: string) => {
    const countryId = await getCountryId(countryCode).then((res) => {
      return {
        id: String(res?.data[0]?.attributes?.drupal_internal__id),
        name: res?.data[0]?.attributes?.name
      }
    })
    return countryId
  }

  const handleEdit = async (account) => {
    setLoading(true)
    await getCountry(account?.country).then((res) => {
      navigate(
        `/bees-games/account-list/edit-account-list`,
        {
          state: {
            ...account,
            country: res
          }
        }
      );
    }).finally(() => setLoading(false))
  }

  const handleDelete = async (uuid: string) => {
    setLoading(true)
    await deleteAccount(uuid)
    await fetchData();
  }

  const InsertActions = (data) => {
    let aux = [];

    const actions = (account) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <>
          <IconButton
            icon={Edit2}
            variant="inherit"
            onClick={() => handleEdit(account)}
          />
          <Modal.Root
            actions={
              <FlexContainer
                width="100%"
                display="inline-flex"
                justifyContent="flex-end"
                gap="0.5rem"
              >
                <Modal.Action>
                  <StyledButton variant="secondary">Cancel</StyledButton>
                </Modal.Action>
                <Modal.Action>
                  <StyledButton variant="destructive" onClick={() => handleDelete(account?.uuidEntity)}>
                    Delete
                  </StyledButton>
                </Modal.Action>
              </FlexContainer>
            }
            trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => { }} />}
            variant="overlay"
            destructive="critical"
          >
            <Heading type="H3" css={{ marginBottom: '1rem' }}>
              Delete account?
            </Heading>
            <Paragraph>{'Do you really wish to delete this account list?'}</Paragraph>
            <Paragraph>{"This action can't be undone."}</Paragraph>
          </Modal.Root>
        </>
      </div>
    );

    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item),
      });
    }
    return aux;
  };

  return (
    <>
      <Container>
        <PageTitle marginBottom="0.5rem" title="Account list" />
        <FlexBtn>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            onClick={() =>
              navigate(
                '/bees-games/account-list/extension-account'
              )
            }
          >
            Add Extension account
          </StyledButton>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            disabled={importAccountStatus}
            onClick={() =>
              navigate(
                '/bees-games/account-list/import-accounts'
              )
            }
          >
            Import accounts
          </StyledButton>
        </FlexBtn>
      </Container>
      {/*importAccountStatus && !loading && (
        <StyledAlert
          className="exportAlert"
          message={'You already have an import running. Wait for it to finish and try again!'}
          actions={[
            {
              action: () => navigate(`/bees-games/account-list/import/extension_account`),
              name: 'Click here to see the details.',
            }
          ]}
          type={'warning'}
          css={{ width: '100%', marginBottom: 20 }}
        />
      )*/}
      {/*<ExportStatus bundle={'cm_extension_account'} campaignId={''} />*/}
      <Card elevated={'medium'} border={'large'}>
        <div style={{ maxWidth: '496px', width: '496px', padding: '1rem' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleChange(e)}
            onClear={(e) => handleChange(e)}
          />
        </div>
        <StyledTable
          loading={loading}
          columns={headers}
          data={accounts}
          pagination={false}
          onRow={(data) => ({
            onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
              navigate('/bees-games/account-list/challenge-status/' + data.drupal_internal__id, {
                state: {
                  accountId: data.drupal_internal__id,
                },
              });
            },
          })}
        />
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          padding={'0.5rem 1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            onChange={handlePagination}
          />
        </FlexContainer>
      </Card>
      <ExportModule
        bundle={'cm_extension_account'}
        campaignId={null}
        disabled={!!!accounts?.length}
      />
    </>
  );
}
