import { Card, IconButton } from '@hexa-ui/components';
import { Plus, Trash2 } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledAlert from '../../../components/Alert/Alert';
import StyledButton from '../../../components/Button/Button';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import useChallengeHandler from '../../../hook/useChallengeHandler';
import { useAppSelector } from '../../../store';
import { CampaignType } from '../../../store/dataTypes';
import { getJsonApiData } from '../../../utils/formatData';
import ChallengeHeaders from '../../json/columnHeaders/ChallengeHeaders.json';

const FlexBtn = styled('div', {
  display: 'flex',
  gap: '$1'
})


const ChallengeTab: React.FC = () => {
  const navigate = useNavigate();
  const { getChallengesByCampaign, deleteChallenge, getChallengeStatus } = useChallengeHandler();
  const campaignDetailData: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const [challenges, setChallenges] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [importChallengeStatus, setImportChallengeStatus] = useState(false);
  const [requestData, setRequestData] = useState({
    total: 0,
    page: 0,
    limit: 25,
    search: ''
  })

  const fetchData = async () => {
    setLoading(true)
    await getChallengesByCampaign(campaignDetailData?.uuid, requestData, true)
      .then(res => {
        const formatData = getJsonApiData(res, true)
        setChallenges(formatData.data)
        setRequestData(prevState => ({ ...prevState, total: formatData.total }))
      })
      .finally(() => setLoading(false))

    await getChallengeStatus(campaignDetailData?.id).then((error) => {
      if (error?.message) setImportChallengeStatus(true);
      else setImportChallengeStatus(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChallenge = (value) => {
    requestData.search = value
    fetchData();
  };

  const debouncedSearch = useCallback(
    debounce((value) => handleSearchChallenge(value), 250),
    []
  );

  const handleChange = (value) => {
    setSearch(value);
    debouncedSearch(value)
  }

  const handleRemove = (uuidEntity: string) => {
    deleteChallenge(uuidEntity).then(() => {
      fetchData();
    });
  };

  function InsertActions(data: any[]) {
    let aux: any[] = [];

    const actions = (uuidEntity: string) => (
      <FlexContainer display="inline-flex" gap="1rem">
        <ModalDelete
          handleDelete={() => handleRemove(uuidEntity)}
          trigger={<IconButton icon={Trash2} variant="inherit" />}
          title="Delete the challenge link to the campaign?"
          description={
            <>
              Do you really wish to delete this link between the challenge and the campaign?
              <br />
              This action can’t be undone.
            </>
          }
        />
      </FlexContainer>
    );

    for (let i = 0; i < data.length; i++) {
      let item: any = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item?.uuidEntity),
      });
    }

    return aux;
  }

  const handlePagination = (page, limit) => {
    requestData.limit = limit
    requestData.page = page >= 1 ? page - 1 : page
    fetchData();
  };

  return (
    <>
      {importChallengeStatus && !loading && (
        <StyledAlert
          message={'You already have an import running. Wait for it to finish and try again!'}
          type={'warning'}
          css={{ width: '100%', marginBottom: 20 }}
        />
      )}
      <Card elevated={'medium'} border={'large'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '1rem 20px',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              id="challenge"
              value={search}
              onChange={(e) => handleChange(e.currentTarget.value)}
              onClear={() => handleSearchChallenge('')}
            />
          </div>
          <FlexBtn>
            <StyledButton
              variant="primary"
              icon={Plus}
              disabled={loading || importChallengeStatus}
              leading
              onClick={() =>
                navigate(`/bees-games/manage-assets/campaign-details/${campaignDetailData?.id}/add-challenge/`)
              }
            >
              Add Challenge
            </StyledButton>
            <StyledButton
              variant="primary"
              disabled={loading || importChallengeStatus}
              icon={Plus}
              leading
              onClick={() =>
                navigate(
                  `/bees-games/manage-assets/campaign-details/${campaignDetailData?.id}/import-challenges`
                )
              }
            >
              Import Challenges
            </StyledButton>
          </FlexBtn>
        </div>
        <StyledTable
          loading={loading}
          columns={ChallengeHeaders}
          data={InsertActions(challenges)}
          pagination={false}
        />
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          padding={'1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            onChange={handlePagination}
          />
        </FlexContainer>
      </Card>
    </>
  );
};

export default ChallengeTab;
