import { FileValidated, Grid } from '@hexa-ui/components';
import { SetStateAction, useContext, useEffect } from 'react';
import StyledFileUploader from '../../../../../components/FileUploader/FileUploader';
import StyledRadio from '../../../../../components/Radio/StyledRadio';
import StyledTextarea from '../../../../../components/Textarea/Textarea';
import { GameSettingsContext } from '../../../../../contexts/gameSettings.context';
import useLuckyNumberHandler from '../../../../../hook/useLuckyNumberHandler';
import { CampaignType } from '../../../../../store/dataTypes';
import { base64CSVConverter } from '../../../../../utils/toBase64';
import checkValidityCSV from '../../../../../utils/verifyCSV';
interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
}

const TabLuckyNumber: React.FC<props> = ({ form, setForm }) => {
  const { uploadLuckyNumberCSV } = useLuckyNumberHandler();
  const { gameSettingsState, setGameSettingsState } = useContext(GameSettingsContext);

  const setCSVError = (isError: boolean) => {
    setGameSettingsState({
      ...gameSettingsState,
      customizeGameLuckyNumber: {
        ...gameSettingsState?.customizeGameLuckyNumber,
        csvError: isError,
      },
    });
  };

  useEffect(() => {
    setCSVError(false);
  }, [form?.settings?.lucky_numbers?.csv_id]);

  const handleFile = async (file: FileValidated[]) => {
    handleChange('csv_id', '');
    if (file.length !== 0) {
      let isValid = await checkValidityCSV(
        file[0].file,
        ['prefix', 'serial_number', 'order_number'],
        form?.settings?.lucky_numbers?.delimiter
      ).then((res: boolean) => res);
      if (isValid) {
        const convertedFile = await base64CSVConverter(file[0].file);
        await uploadLuckyNumberCSV(
          {
            fileName: String(file[0].file.name),
            file: convertedFile,
          },
          String(form?.id)
        ).then((val) => {
          setGameSettingsState({
            ...gameSettingsState,
            customizeGameLuckyNumber: {
              ...gameSettingsState?.customizeGameLuckyNumber,
              csvLuckyNumbers: file,
            },
          });
          handleChange('csv_id', val.fid[0].value);
        }).catch(() => {
          setCSVError(true);
        });
      } else {
        setCSVError(true);
      }
    } else
      setGameSettingsState({
        ...gameSettingsState,
        customizeGameLuckyNumber: {
          ...gameSettingsState?.customizeGameLuckyNumber,
          csvLuckyNumbers: [],
        },
      });
  };

  const handleChange = (id: string, value: string) => {
    setForm({
      ...form,
      settings: {
        ...form?.settings,
        lucky_numbers: {
          ...form?.settings?.lucky_numbers,
          [id]: value,
        },
      },
    });
  };
  return (
    <Grid.Container style={{ width: '100%' }}>
      <Grid.Item
        xl={5}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        style={{ gap: '1rem', flexDirection: 'column' }}
      >
        <StyledTextarea
          label="Description*"
          placeholder="Import made on MM/DD/YY - HH:MM"
          width="100%"
          type="textarea"
          characterCounter
          height={"242px"}
          maxLength={150}
          rows={12}
          style={{ resize: 'none', height: '300px' }}
          onChange={(e) => handleChange('description', e.currentTarget.value)}
          value={form?.settings?.lucky_numbers?.description}
          hasError={!form?.settings?.lucky_numbers?.description && gameSettingsState?.customizeGameLuckyNumber?.emptyFields}
          errorText="Description is required."
        />
      </Grid.Item>
      <Grid.Item xl={4} lg={4} md={5} sm={12} xs={12} style={{ flexDirection: 'column' }}>
        <StyledRadio
          label="Delimiter"
          value={
            form?.settings?.lucky_numbers?.delimiter
              ? form?.settings?.lucky_numbers?.delimiter
              : ','
          }
          defaultValue=","
          type="horizontal"
          hint="Csv delimiter character must be the same as selected in this field."
          style={{ marginBottom: '12px', minWidth: '100%' }}
          options={[
            {
              id: 'comma',
              label: 'Comma',
              value: ',',
            },
            {
              id: 'semicolon',
              label: 'Semicolon',
              value: ';',
            },
          ]}
          onChange={(value) => handleChange('delimiter', value)}
        />
        <StyledFileUploader
          title="Import .csv file*"
          legend={
            <>
              The csv must have the <b>prefix</b>, <b>serial_number</b> and <b>order_number</b>{' '}
              columns.
            </>
          }
          accept=".csv"
          maxHeight="238px"
          width={'100%'}
          value={gameSettingsState?.customizeGameLuckyNumber?.csvLuckyNumbers}
          maxFileSize={5000000}
          maxFiles={1}
          onDrop={(file) => handleFile(file)}
          message="File must be .csv and must be less than 5MB"
          type="file"
          onClean={() => handleFile([])}
          error={
            gameSettingsState?.customizeGameLuckyNumber?.csvError || (gameSettingsState?.customizeGameLuckyNumber?.emptyFields && gameSettingsState?.customizeGameLuckyNumber?.csvLuckyNumbers.length < 1)
              ? {
                message: `The csv is not valid. Columns don't match!`,
              }
              : undefined
          }
        />
         <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              padding: '20px',
              marginBottom: "4.5rem"
            }}
          >
          </div>
        </Grid.Item>
      </Grid.Item>
    </Grid.Container>
  );
};

export default TabLuckyNumber;
