import { Card, FileValidated, Grid, Select } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { apiDrupal } from '../../Api/Api';
import StyledAlert from '../../components/Alert/Alert';
import StyledButton from '../../components/Button/Button';
import FlexContainer from '../../components/FlexContainer';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledSelect from '../../components/Select/Select';
import StyledTextarea from '../../components/Textarea/Textarea';
import { AlertContext } from '../../contexts/alert.context';
import { PrizeType } from '../../store/dataTypes';
import { base_url } from '../../utils/constants';
import verifyImgType from '../../utils/verifyImgType';

const defaultValues = {
  name: '',
  sku: '',
  field_type: '',
  field_image: '',
  description: '',
};



function reducer(state, action) {
  switch (action.type) {
    case 'changed_field': {
      return {
        ...state,
        [action.field.id]: action.field.value,
      };
    }
  }
}

export const PrizeCreation = (): JSX.Element => {
  const navigate = useNavigate();
  const { addToast } = useContext(AlertContext);
  const [prize, setPrize] = useState<PrizeType>();
  const [state, dispatch] = useReducer(reducer, defaultValues);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [imageFileSizeError, setImageFileSizeError] = useState(false);
  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  const params = useParams();

  const [fieldError, setFieldError] = useState({
    name: false,
    sku: false,
    field_type: false,
    field_image: false,
    description: false,
  });

  React.useEffect(() => {
    if (params?.id) {
      apiDrupal.get('/prizes/' + params.id).then((response) => {
        let prize: PrizeType = response.data.data.prize;
        setPrize(prize);
        if (params.id) {
          state.field_image = prize.field_image;
        }

        Object.keys(prize).map((item) => {
          if (item !== 'changed' && item !== 'created' && item !== 'uuid')
            dispatch({
              type: 'changed_field',
              field: {
                value: prize[item],
                id: item,
              },
            });
        });
      });
    } else {
      dispatch({
        type: 'changed_field',
        field: {
          value: '',
          id: 'field_image',
        },
      });
    }
  }, []);

  useEffect(() => {
    setIsValid(
      state.name &&
      state.sku &&
      state.field_type &&
      (state.field_image.target_id || state.field_image.length) &&
      state.description
    );
  }, [state]);

  const handleInputChange = (e, id) => {
    dispatch({
      type: 'changed_field',
      field: {
        value: e.target.value,
        id: id,
      },
    });
  };

  const handleSelectChange = (e, id) => {
    dispatch({
      type: 'changed_field',
      field: {
        value: e,
        id: id,
      },
    });
  };

  const handleCreatePrize = async () => {
    setLoading(true);

    const newFieldError = {
      name: !state.name,
      sku: !state.sku,
      field_type: !state.field_type,
      field_image: !(state.field_image.target_id || state.field_image.length),
      description: !state.description,
    };

    setFieldError(newFieldError);

    if (!isValid) {
      addToast({
        message: 'Please, fill in all fields to continue.',
        type: 'error',
      });
      setLoading(false);
      return;
    }

    await apiDrupal
      .post(base_url + '/file/upload/cm_prize/cm_prize/field_image', state.field_image[0].file, {
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/vnd.api+json',
          'Content-Disposition': 'file; filename="' + state.field_image[0].file.name + '"',
        },
      })
      .then((response) => {
        state.field_image = response.data.fid[0].value;

        apiDrupal.post('/prizes', state).then((response) => {
          navigate('/bees-games/manage-assets/prize-details/' + response.data.data.prize.id);
        });
      })
      .finally(() => setLoading(false));
  };

  const handleUpdatePrize = async () => {
    setLoading(true);

    const newFieldError = {
      name: !state.name,
      sku: !state.sku,
      field_type: !state.field_type,
      field_image: !(state.field_image.target_id || state.field_image.length),
      description: !state.description,
    };

    setFieldError(newFieldError);

    if (!isValid) {
      addToast({
        message: 'Please, fill in all fields to continue.',
        type: 'error',
      });
      setLoading(false);
      return;
    }

    if (Array.isArray(state?.field_image) && typeof state?.field_image !== 'string') {
      await apiDrupal
        .post(base_url + '/file/upload/cm_prize/cm_prize/field_image', state.field_image[0].file, {
          headers: {
            'Content-Type': 'application/octet-stream',
            Accept: 'application/vnd.api+json',
            'Content-Disposition': 'file; filename="' + state.field_image[0].file.name + '"',
          },
        })
        .then((response) => {
          state.field_image = response.data.fid[0].value;
        })
        .finally(() => setLoading(false));
    }

    setLoading(true);

    apiDrupal
      .patch('/prizes/' + params.id, state)
      .then((response) => {
        navigate('/bees-games/manage-assets/prize-details/' + params.id);
      })
      .finally(() => setLoading(false));
  };

  const sizeValidation = (file: FileValidated[]) => {
    if (file[0].file.size > 1000 * 1000) {
      setImageFileSizeError(true);
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      handleSelectChange([], 'field_image');
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
      setFieldError({ ...fieldError, field_image: false });
      handleSelectChange(e, 'field_image');
    }
  };

  if (params.id && !prize) return <></>;

  return (
    <>
      <PageTitle
        marginBottom="1.2rem"
        title={!params.id ? 'Create prize' : 'Edit prize'}
        hint="Enter a name and upload a custom thumbnail to this prize."
      />
      {successMessage && (
        <Box mb={2}>
          <StyledAlert type="success" message={successMessage} style={{ width: '100%' }} />
        </Box>
      )}
      <form>
        <Card
          border="medium"
          elevated="medium"
          css={{
            padding: '24px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <FlexContainer width="100%" display="flex" justifyContent="space-between" flexWrap="wrap">
            <Grid.Item
              xl={8}
              lg={8}
              md={12}
              sm={12}
              xs={12}
              style={{ gap: '1rem', flexDirection: 'column', width: '100%', padding: '0px' }}
            >
              <div style={{ display: 'flex', gap: '1rem', width: '98%' }}>
              <StyledInput
                  width={'100%'}
                  id="prize-name"
                  label="Prize Name"
                  placeholder="Insert your text here"
                  required
                  value={state.name}
                  defaultValue={prize?.name}
                  size="large"
                  onChange={(e) => {
                    handleInputChange(e, 'name');
                    setFieldError({ ...fieldError, name: false });
                  }}
                  hasError={fieldError.name}
                  errorText="Name is required."
                />
                <StyledInput
                  width={'100%'}
                  id="voucher-id"
                  label="Voucher ID*"
                  placeholder="Insert your text here"
                  size="large"
                  value={state.sku}
                  required
                  defaultValue={prize?.sku}
                  onChange={(e) => {
                    handleInputChange(e, 'sku');
                    setFieldError({ ...fieldError, sku: false });
                  }}
                  hasError={fieldError.sku}
                  errorText="Sku is required."
                />
                <StyledSelect
                  error={fieldError.field_type ? 'Type is required' : ''}
                  label="Category*"
                  value={state.field_type}
                  onChange={(e) => {
                    handleSelectChange(e, 'field_type');
                    setFieldError({ ...fieldError, field_type: false });
                  }}
                  defaultValue={prize?.field_type}
                  width={'100%'}
                >
                  <Select.Option value="physical">Physical</Select.Option>
                  <Select.Option value="digital">Digital</Select.Option>
                </StyledSelect>
              </div>
              <StyledTextarea
                id="description"
                characterCounter
                label="Description*"
                placeholder="Enter description"
                maxLength={150}
                width="98%"
                required
                value={state.description}
                defaultValue={prize?.description}
                onChange={(e) => {
                  handleInputChange(e, 'description');
                  setFieldError({ ...fieldError, description: false });
                }}
                hasError={fieldError.description}
                errorText="Descriptionis required."
              />
            </Grid.Item>
            <Grid.Item
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              style={{
                boxShadow: 'none',
                minWidth: '200px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
              }}
            >
              <ImageUploader
                url={base_url + prize?.field_image?.url}
                title="Prize thumbnail*"
                value={state?.field_image?.url ? state?.field_image?.url : state?.field_image}
                error={fileError && { message: 'The selected file cannot be uploaded.' } || fieldError.field_image && { message: "Thumbnail is required." }}
                onDrop={(e) => {
                  verifiedFile(e);
                }}
                id='prize-image'
                onClean={() => handleSelectChange('', 'field_image')}
                message="Allowed PNG, JPG, JPEG, GIF format and must be less than 1MB"
              />
            </Grid.Item>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                gap: '10px',
                marginTop: '20px',
              }}
            >
              <StyledButton
                type="button"
                variant="secondary"
                onClick={function () {
                  navigate('/bees-games/manage-assets#prizes');
                }}
              >
                Cancel
              </StyledButton>
              <StyledButton
                type={'button'}
                isLoading={loading}
                onClick={(e) => {
                  !params.id ? handleCreatePrize() : handleUpdatePrize();
                }}
              >
                {!params.id ? 'Create prize' : 'Save'}
              </StyledButton>
            </div>
          </FlexContainer>
        </Card>
      </form>
    </>
  );
};

export default PrizeCreation;
